/* src/components/Team.css */

/* General Styles */
.team-page {
    background-color: #0d0d0d; /* Dark background */
    color: #e0e0e0; /* Light text */
    /* font-family: 'Arial', sans-serif; */
    padding: 0;
    margin: 0;
}

.team-section {
    padding: 70px 20px;
    text-align: center;
}

.team-section h2 {
    font-size: 2.5em;
    margin-bottom: 50px;
    color: #6badce; /* Accent color */
    text-shadow: 2px 2px 5px #000000; /* Slight shadow */
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}

.team-member {
    background-color: #1a1a1a; /* Slightly lighter background */
    padding: 30px;
    border-radius: 10px;
    max-width: 400px;
    text-align: center;
    transition: transform 0.2s;
}

.team-member:hover {
    transform: translateY(-5px);
    background-color: #2a2a2a;
}

.team-photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    border: 5px solid #6badce; /* Accent border */
}

.team-member h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #6badce; /* Accent color */
}

.team-title {
    font-size: 1.2em;
    color: #a5a5a5; /* Slightly lighter text */
    margin-bottom: 20px;
}

.team-bio {
    font-size: 1em;
    color: #b0b0b0;
    line-height: 1.6;
    margin-bottom: 20px;
}

.portfolio-link {
    font-size: 1em;
    color: #6badce; /* Accent color */
    text-decoration: none;
    border: 2px solid #6badce;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.portfolio-link:hover {
    background-color: #6badce;
    color: #0d0d0d; /* Dark text */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .team-members {
        flex-direction: column;
        align-items: center;
    }
}
