/* src/components/Portfolio.css */

/* General Styles */
.portfolio-page {
    background-color: #0d0d0d;
    color: #e0e0e0;
    margin: 0;
    padding: 0;
}

.portfolio-header {
    text-align: center;
    /* padding: 50px 0;*/
    padding-top: 50px;
}

.portfolio-photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0px;
    border: 5px solid #6badce;
}

.portfolio-header h1 {
    font-size: 2.5em;
    color: #6badce;
}

.portfolio-bio {
    max-width: 800px;
    margin: 0 auto;
    /* padding: 20px; */
    font-size: 1.2em;
    line-height: 1.6;
    text-align: center;
}

.portfolio-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
}

.portfolio-links a {
    color: #6badce;
    text-decoration: none;
    border: 2px solid #6badce;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.portfolio-links a:hover {
    background-color: #6badce;
    color: #0d0d0d;
}

.portfolio-projects {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

.portfolio-projects h2 {
    font-size: 2em;
    color: #6badce;
    margin-bottom: 30px;
    text-align: center;
}

.project {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: transform 0.2s;
}

.project:hover {
    transform: translateY(-5px);
    background-color: #2a2a2a;
}

.project h3 {
    font-size: 1.5em;
    color: #6badce;
    margin-bottom: 10px;
}

.project p {
    font-size: 1em;
    color: #b0b0b0;
    margin-bottom: 10px;
}

.project a {
    color: #6badce;
    text-decoration: none;
    border: 2px solid #6badce;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.project a:hover {
    background-color: #6badce;
    color: #0d0d0d;
}
.no-link {
    color: #b0b0b0;
    font-style: italic;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .portfolio-links {
        flex-direction: column;
        align-items: center;
    }
}
