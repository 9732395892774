/* General Styles */
.privacy {
    background-color: #0d0d0d; /* Dark background */
    color: #e0e0e0; /* Slightly lighter text for contrast */
    min-height: 100vh;
}

.privacy-container {
    max-width: 800px;
    margin: 80px auto 40px auto;
    padding: 20px;
}

h1 {
    font-size: 2.5em;
    color: #6badce; /* Tomato Red */
    text-align: center;
    margin-bottom: 40px;
    text-shadow: 2px 2px 5px #000000;
}

h2 {
    font-size: 1.8em;
    color: #6badce; /* Tomato Red */
    margin-top: 40px;
    margin-bottom: 20px;
    text-shadow: 1px 1px 3px #000000;
}

p {
    font-size: 1em;
    color: #b0b0b0; /* Light grey for paragraph text */
    line-height: 1.6;
    margin-bottom: 20px;
}

ul {
    list-style-type: disc;
    margin-left: 40px;
    color: #b0b0b0;
}

li {
    margin-bottom: 10px;
}

strong {
    color: #e0e0e0; /* Slightly lighter for emphasis */
}

a {
    color: #6badce; /* Tomato Red for links */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .privacy-container {
        margin: 60px 20px;
    }
}
