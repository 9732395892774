/* Navbar Styles */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px; /* Add padding to the navbar to push content away from the edges */
    background-color: rgba(0, 0, 0, 0.9);
    position: sticky;
    top: 0;
    z-index: 1000;
}


.logo img {
    height: 40px; /* Adjust as needed */
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0 auto; /* Center the links within the navbar */
}

.nav-links a {
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    color: #ffffff;
    background-color: transparent;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 0.3s ease;
    text-decoration: none;
}

.nav-links a:hover {
    background-color: #76c7c0;
    color: #1e1e1e;
}

.navbar .nav-links {
    flex-grow: 1;
    justify-content: center;
}
