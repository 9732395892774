/* src/components/Home.css */

/* General Styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #0d0d0d; /* Dark background */
    color: #e0e0e0; /* Slightly lighter text for contrast */
    overflow-x: hidden;
}

h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #6badce; /* Tomato Red for headings */
}

h3 {
    font-size: 1.5em;
    color: #6badce; /* Tomato Red for subheadings */
}

p {
    font-size: 1em;
    color: #b0b0b0; /* Light grey for paragraph text */
}

/* Features Section */
.features-section {
    padding: 70px 20px;
    background-color: #1a1a1a; /* Darker grey */
    text-align: center;
}

.features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.feature {
    flex: 1 1 250px;
    max-width: 300px;
    background-color: #2a2a2a; /* Dark grey for feature background */
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.2s;
}

.feature:hover {
    transform: translateY(-5px);
    background-color: #333333; /* Slightly lighter grey on hover */
}

.feature h3 {
    margin-bottom: 15px;
    color: #6badce; /* Tomato Red for feature headings */
    /* Tiny shadow */
    text-shadow: 1px 1px 2px #000000;
}

.feature p {
    color: #999999; /* Medium grey for feature text */
}

/* Download Section */
.download-section {
    padding: 70px 20px;
    background-color: #0d0d0d; /* Dark background */
    text-align: center;
}

.download-section h2 {
    color: #6badce; /* Tomato Red for download section headings */
    text-shadow: 2px 2px 5px #000000; /* Slight shadow */
    margin-bottom: 20px;
}

.download-section p {
    color: #a5a5a5; /* Medium grey for download section text */
    max-width: 600px;
    margin: 0 auto 30px auto;
}

.app-store-badge {
    width: 200px;
    height: auto;
    transition: transform 0.2s;
}

.app-store-badge:hover {
    transform: scale(1.05);
}

/* Call to Action Section */
.call-to-action {
    text-align: center;
    padding: 60px 20px;
    background-color: #1a1a1a; /* Darker grey */
}

.call-to-action h2 {
    color: #6badce; /* Tomato Red for call-to-action */
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px #000000; /* Slight shadow */
}

/* Footer */
.footer {
    text-align: center;
    padding: 20px;
    background-color: #0d0d0d; /* Very dark grey */
    color: #4d4d4d; /* Medium grey for footer text */
}

.footer a {
    color: #6badce; /* Tomato Red for footer links */
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .features-container {
        flex-direction: column;
        align-items: center;
    }
}

/* Add some flair */
.feature::before {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #6badce; /* Tomato Red accent */
    margin: 0 auto 15px auto;
}

/* Additional Styles */
a {
    color: #6badce; /* Tomato Red for all links */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.social-icon {
    width: 40px;
    height: 40px;
    margin-top: 15px;
    transition: transform 0.2s;
}

.social-icon:hover {
    transform: scale(1.1);
}