/* src/components/Header.css */

/* General Styles for Header */
.hero {
    background-image: url('../../public/strobe-party.PNG');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    color: #ffffff;
    text-align: center;
    overflow: hidden;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.6); /* Semi-transparent overlay */
    z-index: 1;
}

.navbar {
    z-index: 1000;
}

.hero-content {
    position: relative;
    z-index: 2;
    padding: 0 20px;
    margin-top: 150px; 
}

.center-logo img {
    max-width: 800px;
    width: 80%;
    height: auto;
    margin: 0 auto 20px auto;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    /* add border/show to make the text pop */
    text-shadow: 2px 2px 5px #000000;

}

.hero p {
    font-size: 1.3rem;
    margin-bottom: 30px;
    text-shadow: 2px 2px 5px #000000;
}

/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    width: 100%;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
}

.nav-links a {
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    color: #ffffff;
    background-color: transparent;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 0.3s ease;
    text-decoration: none;
}

.nav-links a:hover {
    background-color: #76c7c0;
    color: #1e1e1e;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .hero {
        height: auto;
        min-height: 100vh;
    }

    .hero-content {
        margin-top: 80px;
    }

    .center-logo img {
        max-width: 80%;
        padding-top: 100px;
    }

    .hero h1 {
        font-size: 2.2rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .nav-links a {
        font-size: 0.8rem;
        padding: 4px 12px;
    }
}
