/* Support Page Styles */
.support {
    background-color: #0d0d0d; /* Dark background */
    color: #e0e0e0; /* Light text */
    min-height: 100vh;
}

.support-container {
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
}

/* Sections */
.overview-section, .faq-section, .contact-section, .privacy-policy-section {
    margin-bottom: 40px;
}

h1, h2 {
    font-size: 2.5em;
    color: #6badce; /* Tomato Red for headings */
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px #000000;
}

h3 {
    font-size: 1.5em;
    color: #6badce; /* Tomato Red for subheadings */
    margin-bottom: 10px;
}

p {
    font-size: 1em;
    color: #b0b0b0; /* Light grey */
    line-height: 1.6;
}

/* FAQ */
.faq {
    margin-bottom: 20px;
}

.faq h3 {
    margin-bottom: 5px;
}

.faq p {
    margin-bottom: 15px;
}

/* Contact Form */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form input, .contact-form textarea {
    background-color: #2a2a2a; /* Dark grey for input fields */
    border: 1px solid #444;
    color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    font-size: 1em;
}

.contact-form button {
    background-color: #6badce; /* Tomato Red */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #57a0ba; /* Slightly darker on hover */
}

/* Privacy Section */
.privacy-policy-section a {
    color: #6badce; /* Tomato Red for links */
    text-decoration: none;
}

.privacy-policy-section a:hover {
    text-decoration: underline;
}

/* Footer */
.footer {
    text-align: center;
    padding: 20px;
    background-color: #0d0d0d; /* Very dark grey */
    color: #4d4d4d; /* Medium grey for footer text */
}

.footer a {
    color: #6badce; /* Tomato Red for footer links */
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .support-container {
        margin: 60px 20px;
    }
}
