
@font-face {
    font-family: 'Kenyan Coffee';
    src: url('../assets/fonts/kenyan-coffee/kenyan coffee rg.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kenyan Coffee';
    src: url('../assets/fonts/kenyan-coffee/kenyan coffee rg it.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Kenyan Coffee';
    src: url('../assets/fonts/kenyan-coffee/kenyan coffee bd.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Kenyan Coffee';
    src: url('../assets/fonts/kenyan-coffee/kenyan coffee bd it.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}
/* Global font styles */
body {
    font-family: 'Kenyan Coffee', sans-serif;
    font-size: 1.25em;
}
